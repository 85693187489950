@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@font-face {
  font-family: "Wagoon Bold";
  src: url(./fonts/Wagoon_Bold_Bold.ttf);
  /* font-weight: bolder; */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.clouds {
  animation: moveClouds 20s linear infinite;
}
@keyframes moveClouds {
  to {
    transform: translateX(-100vw);
  }
}

.glow {
  animation: glow 1s ease-in-out infinite alternate;
  box-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 4px #21c107, 0 0 6px #21c107,
    0 0 8px #21c107, 0 0 10px #21c107, 0 0 12px #21c107; /* Box shadow */
}

@keyframes glow {
  from {
    box-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 4px #21c107, 0 0 6px #21c107,
      0 0 8px #21c107, 0 0 10px #21c107, 0 0 12px #21c107; /* Initial box shadow */
  }

  to {
    box-shadow: 0 0 2px #fff, 0 0 4px #21c107, 0 0 6px #21c107, 0 0 8px #21c107,
      0 0 10px #21c107, 0 0 12px #21c107, 0 0 14px #21c107; /* New box shadow */
  }
}
